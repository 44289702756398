import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./i18n";

import Landing from "views/Landing.js";

ReactDOM.render(
  <Suspense fallback="loading">
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Landing} />
        <Redirect from="/landing" to="/" />
      </Switch>
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);
